<template>
    <div class="setting">
        <Top name="设置" back email></Top>
        <div class="setting_w">
            <van-cell-group>
                <van-cell  title="账号" :value="account">
                    <template #icon>
                        <van-icon :name="settingSrc1" />
                    </template>
                </van-cell>
                <!-- <van-cell is-link to="/datamodify" title="基础资料修改" value="去修改">
                    <template #icon>
                        <van-icon :name="settingSrc2" />
                    </template>
                </van-cell> -->
                <van-cell is-link to="/passwordset" title="密码管理" value="去修改">
                    <template #icon>
                        <van-icon :name="settingSrc3" />
                    </template>
                </van-cell>
                <!-- <van-cell is-link to="/datamodify" title="基础资料修改" value="去修改">
                    <template #icon>
                        <van-icon :name="settingSrc2" />
                    </template>
                </van-cell>
                <van-cell is-link to="/passwordset" title="密码管理" value="去修改">
                    <template #icon>
                        <van-icon :name="settingSrc3" />
                    </template>
                </van-cell> -->
            </van-cell-group>
            <!-- <van-cell-group class="set_center">
                <van-cell value="切换账号" />
            </van-cell-group> -->
            <van-cell-group class="set_center">
                <van-cell value="退出登录" @click="show = true;" />
            </van-cell-group>
            <van-overlay :show="show" @click="show = false">
                <div class="wrapper">
                    <div class="quit">
                        <p>是否确认退出登录？</p>
                        <p>
                            <span @click="show = false;">取消</span>
                            <span @click="quitConfirm">确认</span>
                        </p>
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Setting',
    components: {
        Top,
    },
    data() {
        return {
            settingSrc1: require('../../assets/images/setting_icon1.png'),
            settingSrc2: require('../../assets/images/setting_icon2.png'),
            settingSrc3: require('../../assets/images/setting_icon3.png'),
            account: '',
            show: false
        }
    },
    created(){
        let username = localStorage.getItem('username');
        this.account = username;
    },
    methods: {
        //确认退出登录
        quitConfirm() {
            this.show = false;
            this.$api.login.loginOut().then(res => {
                if (res.code == 200) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('username');
                    this.$router.push('/login');
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
}
</script>
<style scoped>
.setting{
    min-height: 100%;
    background-color: #ececec;
}
.setting .van-cell{
    text-align: left;
    align-items: center;
}
.setting_w img{
    display: inline-block;
    /* vertical-align: middle; */
    margin-right: .5625rem;
}
.setting_w .van-cell-group{
    margin-bottom: .4375rem;
}
.setting_w .van-cell::after{
    border-bottom: 1px dashed #b3b3b3;
}
.setting_w .set_center .van-cell__value--alone{
    text-align: center;
}
.setting_w .wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}
.setting_w .quit{
    width: 80%;
    background-color: #fff;
    color: #313131;
    font-size: 1rem;
    border-radius: 15px;
}
.setting_w .quit p:first-child{
    padding: 2rem 0 1.5rem;
    border-bottom: 1px solid #b3b3b3;
}
.setting_w .quit p:last-child{
    display: flex;
}
.setting_w .quit p:last-child span{
    flex: 1;
    padding: 1.125rem 0 .9375rem;
}
.setting_w .quit p:last-child span:first-child{
    border-right: 1px solid #b3b3b3;
}
</style>